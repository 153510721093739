<template>
    <card-table-search :fields="fields"
                       :actions="actions"
                       :items="items"
                       title="Liste des tags"
                       :custom-rendered="['important']">
        <template #important="{row}">
            <b-badge :variant="row.item.important ? 'success' : 'danger'">
                {{ row.item.important ? 'oui' : 'non' }}
            </b-badge>
        </template>
    </card-table-search>
</template>

<script>
    import {apiPath}      from '@/util/http';
    import alert          from '@/util/alert';

    const CardTableSearch   = () => import('@/components/CardTableSearch');
    const EditText          = () => import('@/components/modals/quick_edit/EditText');

    export default {
        name: "AdminBorrowableTags",
        components: {CardTableSearch},
        data() {
            return {
                fields: [
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'important',
                        label: 'Important',
                        formatter: value => value ? 'oui' : 'non',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Renommer',
                        handler: ({item}) => this.editTagName(item)
                    },
                    {
                        key: 'action_important',
                        color: () => 'success',
                        icon: 'angle-double-up',
                        tooltip: 'Catégorie importante',
                        handler: ({item}) => this.makeImportant(item),
                        display: ({item}) => !item.important
                    },
                    {
                        key: 'action_unimportant',
                        color: () => 'warning',
                        icon: 'angle-double-down',
                        tooltip: 'Simple tag',
                        handler: ({item}) => this.makeUnimportant(item),
                        display: ({item}) => item.important
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.delete(item)
                    }
                ],
                items: []
            };
        },
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('get_all_borrowable_tags'))
                    .then(response => this.items = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des tags'))
                    .finally(() => alert.stopLoading());
            },
            editTagImportance(tag, important) {
                alert.loading();
                this.axios.post(apiPath('edit_borrowable_tag', {tag: tag.id}), {important: important})
                    .then(() => this.loadData())
                    .catch(() => {
                        this.$toaster.error('Impossible de modifier le tag');
                        alert.stopLoading();
                    });
            },
            editTagName(tag) {
                this.$store.dispatch('modal/create', {
                    component: EditText,
                    props: {
                        value: tag.nom,
                        callback: input => {
                            alert.loading();
                            this.axios.post(apiPath('edit_borrowable_tag', {tag: tag.id}), {nom: input})
                                .then(() => this.loadData())
                                .catch(() => {
                                    this.$toaster.error('Impossible de modifier le tag');
                                    alert.stopLoading();
                                });
                        }
                    }
                });
            },
            makeImportant(tag) {
                this.editTagImportance(tag, true);
            },
            makeUnimportant(tag) {
                this.editTagImportance(tag, false);
            },
            delete(tag) {
                alert.confirm(
                    `Supprimer le tag « ${tag.nom} » ? Il sera enlevé de tous les jeux, livres et JdR auquel il pourrait être associé`,
                    () => {
                        alert.loading();
                        this.axios.delete(apiPath('delete_borrowable_tag', {tag: tag.id}))
                                .then(() => this.loadData())
                                .catch(() => {
                                    this.$toaster.error('Impossible de supprimer le tag');
                                    alert.stopLoading();
                                });
                    }
                );
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
